import { Player } from '@lottiefiles/react-lottie-player'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import SelectConnectModal from '../Modals/SelectConnectionModal'
import { useAccount, useConnect } from 'wagmi'
import { Router, useRouter } from 'next/router'

interface INavbarDesktop {}

const NavbarDesktop: React.FC<INavbarDesktop> = () => {
  const [isOpen, setIsOpen] = useState(false)

  const [connectOpen, setConnectOpen] = useState(false)
  const router = useRouter()

  const [{ data: connectData }, connect] = useConnect()
  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  })

  const animation = useRef<Player>(null)

  const handleClick = () => {
    if (!isOpen) {
      animation.current?.setPlayerDirection(1)
      animation.current?.setPlayerSpeed(2)
      animation.current?.play()
    } else {
      animation.current?.setPlayerDirection(-1)
      animation.current?.setPlayerSpeed(2)
      animation.current?.play()
    }
    setIsOpen(!isOpen)
  }

  const handleConnectMetamask = useCallback(async () => {
    if (typeof window.ethereum == 'undefined') {
      return
    }

    if (connectData.connected) return
    connect(connectData.connectors[0])
  }, [connectData.connected, connectData.connectors, connect])

  const handleConnect = useCallback(async () => {
    if (connectData.connected) return
    connect(connectData.connectors[1])
  }, [connectData.connected, connectData.connectors, connect])

  return (
    <div className='fixed top-2 right-2 z-50'>
      <div
        className={`bg-violet rounded-3xl border-[3px] border-black box-shadow-md`}
      >
        <div className='flex flex-row justify-between items-center h-16 pl-5'>
          <SelectConnectModal
            isVisible={connectOpen}
            closeModal={() => setConnectOpen(false)}
            walletConnect={handleConnect}
            metamask={handleConnectMetamask}
          />
          <div
            onClick={handleClick}
            className='transition-all duration-300 ease-in-out mr-3 cursor-pointer'
          >
            <Player
              ref={animation}
              keepLastFrame
              src='/assets/animations/burger.json'
              className='w-12'
            />
          </div>

          <div className='flex flex-row justify-between items-center transition-all mr-3'>
            {isOpen && (
              <>
                <Link href='/#home'>
                  <a className='text-yellow text-stroke-1 text-xl leading-4 font-black mx-2 cursor-pointer antialiased'>
                    Home
                  </a>
                </Link>
                <Link href='/#manifesto'>
                  <a className='text-yellow text-stroke-1 text-xl leading-4 font-black mx-2 cursor-pointer antialiased'>
                    Manifesto
                  </a>
                </Link>
                <Link href='/#projects'>
                  <a className='text-yellow text-stroke-1 text-xl leading-4 font-black mx-2 cursor-pointer antialiased'>
                    Projects
                  </a>
                </Link>
                <Link href='/#services'>
                  <a className='text-yellow text-stroke-1 text-xl leading-4 font-black mx-2 cursor-pointer antialiased'>
                    Services
                  </a>
                </Link>

                <div
                  className='text-yellow text-stroke-1 text-xl leading-4 font-black mx-2 cursor-pointer antialiased'
                  onClick={() => {
                    accountData?.address
                      ? router.push(`/profile/${accountData.address}`)
                      : setConnectOpen(true)
                  }}
                >
                  {accountData?.address ? 'Profile' : 'Login'}
                </div>
              </>
            )}
          </div>
          <div
            className='bg-cyan rounded-3xl border-[3px] border-black h-16 flex flex-row justify-center items-center px-5 box-content cursor-pointer -mr-[3px]'
            onClick={() => {
              open('https://discord.gg/acUsZMBBcR')
            }}
          >
            <h3 className='text-yellow text-stroke-1 text-xl leading-4 font-black antialiased'>
              Join Our Discord
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarDesktop
