import styles from '../../styles/PrimaryButton.module.scss'
import React from 'react'

interface IPrimaryButton {
  title: string
  full?: boolean
  className?: string
  bgShadowClass?: string
  border?: string
  bgClass?: string
  onClick: () => void
}

const PrimaryButton: React.FC<IPrimaryButton> = ({
  title,
  full = false,
  className,
  onClick,
  bgClass = 'bg-cyan',
  bgShadowClass = 'bg-darkCyan',
  border = 'border-darkCyan',
}) => {
  return (
    <button
      onClick={() => onClick()}
      className={`${styles.btn} ${className} ${bgShadowClass} before:${bgShadowClass}`}
      style={{ width: full ? 310 : 200 }}
    >
      <span
        className={`${styles.btnWrapper} ${bgClass} ${border} border-[3px]`}
        style={{ width: full ? 310 : 200 }}
      >
        <span className={`${styles.btnText} text-lg text-white font-semibold`}>
          {title}
        </span>
        <img
          loading='lazy'
          src='/assets/images/common/glass.png'
          alt='glass'
          className={styles.btnGlass}
          width='59px'
          height='50px'
        />
      </span>
    </button>
  )
}

export default PrimaryButton
