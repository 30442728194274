import { useEffect } from 'react'
import useDeviceDetection from '../../hooks/useDeviceDetection'
import useClickOutsideToClose from '../../hooks/useClickOutsideToClose'
import { FaTimes } from 'react-icons/fa'
import colors from '../../utils/colors'

export interface IBaseModal {
  isOpen: boolean
  children?: React.ReactNode
  closeModal: () => void
  hideCloseIcon?: boolean
}

const BaseModal: React.FC<IBaseModal> = ({
  isOpen,
  closeModal,
  children,
  hideCloseIcon = false,
}) => {
  useEffect(() => {
    const body = document.querySelector('body')

    if (isOpen) body?.classList.add('overflow-hidden')
    else body?.classList.remove('overflow-hidden')
  }, [isOpen])

  const { isDesktop } = useDeviceDetection()

  const domNode = useClickOutsideToClose(() => {
    if (closeModal) {
      closeModal()
    }
  })
  if (!isOpen) return <></>

  return (
    <div
      className='opacity-1 fixed top-0 left-0 right-0 bottom-0 z-[60] flex h-full  w-full items-end  justify-center overflow-y-hidden backdrop-blur md:items-center'
      style={{ backgroundColor: '#2B8EA377' }}
    >
      <div
        ref={domNode}
        className={`absolute 
                 z-[1000] -translate-x-1/2 -translate-y-1/2 bg-pink 
                left-1/2 top-1/2 
                rounded-10 py-[40px] px-[16px] rounded-[30px]`}
      >
        {!hideCloseIcon && (
          <div
            className='absolute top-[16px] right-[16px] cursor-pointer'
            onClick={() => {
              closeModal()
            }}
          >
            <FaTimes
              onClick={() => closeModal()}
              size={24}
              color={colors.white}
            />
          </div>
        )}

        <div className='ml-auto mr-auto flex flex-col justify-center items-center'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default BaseModal
