module.exports = {
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/containers/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '2100px',
    },

    colors: {
      white: '#FFFFFF',
      black: '#000000',
      lightBlack: '#222222',
      gray: '#374151',
      lightGray: '#7C7474',
      red: '#FE5C68',
      brown: '#795000',
      orange: '#FFA800',
      yellow: '#FFEC00',
      green: '#8EE44A',
      blue: '#175ca1',
      darkCyan: '#2B8EA3',
      cyan: '#1dd7ff',
      darkViolet: '#512886',
      violet: '#7130C5',
      lightViolet: '#8D5BCE',
      pink: '#D68DF0',
      lightPink: '#EAC0F9',
      accentPink: '#fe89cd',
      transparent: 'transparent',
    },
    gridTemplateColumns: {
      xs: 'repeat(4, 70px)',
      sm: 'repeat(4, 74px)',
      md: 'repeat(8, 57px)',
      lg: 'repeat(8, 89px)',
      xl: 'repeat(12, 67px)',
      '2xl': 'repeat(12, 80px)',
      '3xl': 'repea(12, 88px)',
      '4xl': 'repeat(12, 120px)',
    },
    container: {},
    extend: {},
  },
  plugins: [],
}
