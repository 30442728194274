import Image from 'next/image'
import React from 'react'

interface IMetamaskButton {
  title: string
  full?: boolean
  onClick: () => void
}

const MetamaskButton: React.FC<IMetamaskButton> = ({
  title,
  full = false,
  onClick,
}) => {
  return (
    <div
      className='border-4 border-white rounded-md flex flex-row justify-center items-center mt-4 cursor-pointer'
      style={{ width: full ? 310 : 200, height: 60 }}
      onClick={() => onClick()}
    >
      <h3 className='text-lg text-white font-medium mr-4'>{title}</h3>
      <Image
        src={'/assets/images/common/metamask.webp'}
        width={40}
        height={40}
        loading='lazy'
      />
    </div>
  )
}

export default MetamaskButton
