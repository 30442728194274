import { useMediaQuery } from '@react-hook/media-query'

const useDeviceDetection = () => {
  const isTablet = useMediaQuery(
    'only screen and (min-width: 641px) and (max-width: 1023px)',
  )
  const isMobile = useMediaQuery('only screen and (max-width: 767px)')

  const isDesktop = !isMobile && !isTablet

  return { isTablet, isMobile, isDesktop }
}

export default useDeviceDetection
